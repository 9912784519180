<template>
  <v-card elevation="0">
    <div class="flex align-center justify-center text-center mb-6">
      <json-excel
        :data="exportedStatisticsData"
        :fields="excelColumnHeaders"
        :name="fileName"
        :before-generate="onBeforeGenerateStatistics"
        :before-finish="onBeforeDownloadStatistics"
      >
        <a class="text-decoration-underline">
          {{ $t('campaign_statistics.download_statistics') }}
        </a>
      </json-excel>
    </div>
    <statistics-search v-if="groupBySite || groupBySpot || groupByBrowser" v-model="search" :placeholder="$t('campaign_statistics.search')" />
    <v-data-table
      ref="dataTable"
      v-model="selectedItems"
      :loading="campaignsStatisticsIsLoading"
      :headers="headers"
      :items="mappedStatisticsItems"
      :options.sync="options"
      :show-select="blacklistingIsEnabled"
      :search="search"
      :item-key="siteKey"
      hide-default-footer
      mobile-breakpoint="0"
      @page-count="getPageCount($event)"
      @current-items="filteredStatisticsItems = $event"
    >
      <template v-if="groupBySite || groupBySpot || groupByBrowser" #item.data-table-select="{ isSelected, select, item }">
        <v-checkbox v-if="item.isSelectable" :input-value="isSelected" @change="select" />
      </template>
      <template #item.src="{ item }">
        <div v-if="item.src" class="py-3">
          <hover-image :src="item.src" />
        </div>
      </template>

      <template #item.campaign="{ item }">
        <router-link
          :to="{
            name: 'Campaign',
            params: {
              id: item.campaign_id
            }
          }"
        >
          {{ item.campaign }}
        </router-link>
      </template>

      <template #item.banner="{ item }">
        <router-link :to="{ name: routeNames.AD, params: { id: item.ad_id } }">
          {{ item.banner }}
        </router-link>
      </template>

      <template #item.webPush="{ item }">
        <router-link :to="{ name: routeNames.AD, params: { id: item.ad_id } }">
          {{ item.webPush }}
        </router-link>
      </template>

      <template #item.inStreamAd="{ item }">
        <router-link
          :to="{
            name: routeNames.AD,
            params: {
              id: item.ad_id
            }
          }"
        >
          {{ item.inStreamAd }}
        </router-link>
      </template>

      <template #item.nativeAd="{ item }">
        <router-link
          :to="{
            name: routeNames.AD,
            params: {
              id: item.ad_id
            }
          }"
        >
          {{ item.nativeAd }}
        </router-link>
      </template>

      <template #item.directLink="{ item }">
        <router-link
          :to="{
            name: routeNames.AD,
            params: {
              id: item.ad_id
            }
          }"
        >
          {{ item.directLink }}
        </router-link>
      </template>

      <template #item.iosCalendar="{ item }">
        <router-link :to="{ name: routeNames.AD, params: { id: item.ad_id } }">
          {{ item.iosCalendar }}
        </router-link>
      </template>

      <template #item.site="{ item }">
        {{ site(item) }}
      </template>

      <template #item.ad_type_name="{ item }">
        <router-link :to="{ name: routeNames.AD, params: { id: item.ad_id } }">
          {{ item.ad_type_name }}
        </router-link>
      </template>

      <template v-if="statisticsItems.length" #body.append>
        <total-statistics :headers="headers" :items="totalStatistics" :has-checkboxes="blacklistingIsEnabled" />
      </template>

      <template #header.reserved_balance>
        {{ $t('campaign_statistics.reserved') }}
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <c-btn
              v-bind="attrs"
              icon
              small
              :href="helpUrls.balance_reserved"
              target="_blank"
              :icon-props="{
                icon: 'mdi-help-circle',
                color: 'primary',
                small: 'small'
              }"
              v-on="on"
            />
          </template>
          <span> {{ $t('campaign_statistics.why_there_is_a_reservation') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <black-listing
      v-if="blacklistingIsEnabled"
      :selected-items="selectedItems"
      :blacklisting-group="groupBy"
    />
    <data-table-footer
      :items-per-page="options.itemsPerPage"
      :current-page="options.page"
      :page-count="pageCount"
      @change-items-per-page="changeItemsPerPage($event)"
      @change-current-page="changeCurrentPage($event)"
    />
  </v-card>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import JsonExcel from 'vue-json-excel'
  import HoverImage from '@/components/HoverImage/Index.vue'
  import TotalStatistics from '@/components/TotalStatistics.vue'
  import BlackListing from './BlackListing/Index.vue'
  import StatisticsSearch from '@/views/CampaignsStatistics/StatisticsSearch.vue'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'StatisticsTable',
    components: {
      StatisticsSearch,
      BlackListing,
      TotalStatistics,
      CBtn,
      HoverImage,
      DataTableFooter,
      JsonExcel
    },
    data() {
      return {
        exportedStatisticsData: [],
        selectedItems: [],
        filteredStatisticsItems: [],
        options: {
          itemsPerPage: 15,
          page: 1
        },
        search: '',
        pageCount: 1,
        routeNames
      }
    },
    computed: {
      ...mapState('campaignsStatistics', [
        'campaignsStatisticsIsLoading',
        'statisticsItems',
        'tableOptions',
        'tablePerPagePagination',
        'groupBy',
        'selectedCampaigns'
      ]),
      ...mapState('settings', ['adNetwork', 'undefinedSiteId', 'undefinedSpotTdsId']),
      ...mapGetters('campaignsStatistics', ['selectedGroupByOption', 'totalStatistics']),
      ...mapGetters('settings', ['helpUrls']),
      fileName() {
        return `${this.adNetwork.name}-statistic.xls`
      },
      excelColumnHeaders() {
        return this.headers.reduce((acc, headerItem) => {
          const header = !this.adNetwork.showSiteDomains && headerItem === 'site' ? 'site_id' : headerItem
          acc[this.$t(`campaign_statistics.headers.${header.value}`)] = header.value
          return acc
        }, {})
      },
      mappedStatisticsItems() {
        if (this.groupBySite) {
          return this.statisticsItems.map((item) => ({ ...item, isSelectable: item.site_id !== this.undefinedSiteId }))
        }
        if (this.groupBySpot) {
          return this.statisticsItems.map((item) => ({ ...item, isSelectable: item.spot_id !== this.undefinedSpotTdsId }))
        }
        if (this.groupByBrowser) {
          return this.statisticsItems.map((item) => ({ ...item, isSelectable: true }))
        }
        return this.statisticsItems
      },
      tableOptionsMutable: {
        get() {
          return this.tableOptions
        },
        set(options) {
          this.setTableOptions(options)
        }
      },
      siteKey() {
        if (this.groupBySite) {
          return this.adNetwork.showSiteDomains ? 'site' : 'site_id'
        }
        if (this.groupByBrowser) {
          return 'browser'
        }
        return 'spot_id'
      },
      groupBySite() {
        return this.groupBy === 'site'
      },
      groupBySpot() {
        return this.groupBy === 'spot'
      },
      groupByBrowser() {
        return this.groupBy === 'browser'
      },
      blacklistingIsEnabled() {
        return (this.groupBySite || this.groupBy === 'spot' || this.groupBy === 'browser')
          && !!this.selectedCampaigns.length
      },
      headers() {
        return this.selectedGroupByOption.headers.map((value) => ({
          value: value === 'site' ? this.siteKey : value,
          text: this.$t(`campaign_statistics.headers.${value}`),
          width: '120px',
          class: 'black--text',
          filterable: ['spot_id', 'site'].includes(value)
        }))
      }
    },
    watch: {
      selectedCampaigns() {
        this.selectedItems = []
      },
      groupBySite() {
        this.search = ''
      },
      groupBySpot() {
        this.search = ''
      },
      groupBy() {
        this.selectedItems = []
      }
    },
    methods: {
      ...mapActions('campaignsStatistics', ['setTableOptions', 'fetchStatistics']),
      site(item) {
        return this.adNetwork.showSiteDomains ? item.site : item.site_id
      },
      generateTotalRow(headers) {
        return headers.reduce((row, header) => {
          const headerConfig = this.headers.find((item) => item.value === header)

          if (headerConfig) {
            row[header] = this.totalStatistics[headerConfig.value]
          }

          if (header === headers[0]) {
            row[header] = this.$t('main.total')
          }

          return row
        }, {})
      },
      onBeforeGenerateStatistics() {
        if (!this.$refs.dataTable) return

        const { options, locale, items } = this.$refs.dataTable
        const { sortBy = [], sortDesc = [] } = options

        const sortedData = this.$refs.dataTable.customSort(items, sortBy, sortDesc, locale)

        const exelHeaders = Object.values(this.excelColumnHeaders)
        const totalRow = this.generateTotalRow(exelHeaders)

        this.exportedStatisticsData = [...sortedData, totalRow]
      },
      onBeforeDownloadStatistics() {
        this.exportedStatisticsData = []
      },
      changeCurrentPage(event) {
        this.options.page = event
      },
      changeItemsPerPage(event) {
        this.options.itemsPerPage = event
      },
      getPageCount(event) {
        this.pageCount = event
      }
    }
  }
</script>
