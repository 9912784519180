<template>
  <div
    ref="el"
    v-resize="onResize"
  >
    <statistics-chart-columns
      :axis="axis"
      @change-axis="changeAxis($event)"
    />
    <div class="pr-md-6">
      <custom-chart
        :series="series"
        :options="options"
        type="line"
        height="500"
        width="100%"
      />
      <v-row no-gutters>
        <v-col v-if="pagesCount > 1" cols="12" md="4" class="ml-auto">
          <v-pagination
            v-model="chartPage"
            :length="pagesCount"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import moment from 'moment'
  import CustomChart from '@/components/CustomChart.vue'
  import StatisticsChartColumns from './StatisticsChartColumns.vue'

  export default {
    name: 'Chart',
    components: {
      StatisticsChartColumns,
      CustomChart
    },
    data() {
      return {
        width: 0,
        defaultOptions: {
          stroke: {
            width: [0, 4],
            curve: 'smooth'
          }
        },
        axis: {
          first: 'cost',
          second: 'impressions'
        },
        chartPage: 1,
        totalsHeader: [
          'date',
          'campaign_id',
          'hour',
          'id',
          'src',
          'browser',
          'carrier',
          'country',
          'operatingSystem',
          'site'
        ]
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      ...mapState('campaignsStatistics', ['groupBy', 'statisticsItems']),
      isTablet() {
        return this.$vuetify.breakpoint.smAndUp
      },
      isDesktop() {
        return this.$vuetify.breakpoint.lgAndUp
      },
      yaxis() {
        return [
          {
            title: {
              text: this.$t(`campaign_statistics.headers.${this.axis.first}`)
            }
          },
          {
            opposite: true,
            title: {
              text: this.$t(`campaign_statistics.headers.${this.axis.second}`)
            }
          }
        ]
      },
      xaxis() {
        if (this.groupBy === 'date' || this.groupBy === 'hour') {
          return {
            type: 'datetime',
            categories: this.categories
          }
        }
        return {
          type: 'category',
          categories: []
        }
      },
      tooltip() {
        return {
          x: {
            format: this.groupBy === 'hour' ? 'dd MMM HH:mm' : 'dd MMM'
          }
        }
      },
      itemsCountPerPage() {
        const pixelsForOneItem = 25
        return Math.ceil(this.width / pixelsForOneItem)
      },
      limitedItems() {
        const from = (this.chartPage - 1) * this.itemsCountPerPage
        const to = from + this.itemsCountPerPage

        return this.statisticsItems.slice(from, to)
      },
      pagesCount() {
        return Math.ceil(this.statisticsItems.length / this.itemsCountPerPage)
      },
      categories() {
        const groupByHour = 'hour'
        return this.limitedItems.map(
          ({ hour, date }) => (this.groupBy === groupByHour ? moment(hour).utcOffset(0, true).format() : date)
        )
      },
      firstSeries() {
        const series = this.limitedItems.map((item) => toFixedByDefault(parseFloat(item[this.axis.first])))
        return {
          name: this.$t(`campaign_statistics.headers.${this.axis.first}`),
          type: 'column',
          data: series
        }
      },
      secondSeries() {
        const series = this.limitedItems.map((item) => toFixedByDefault(parseFloat(item[this.axis.second])))
        return {
          name: this.$t(`campaign_statistics.headers.${this.axis.second}`),
          type: 'line',
          data: series
        }
      },
      labels() {
        if (
          this.limitedItems[0]
          && (this.groupBy in this.limitedItems[0] || this.groupBy === 'spot')
          && this.groupBy !== 'date'
          && this.groupBy !== 'hour'
        ) {
          let grouping = this.groupBy
          if (this.groupBy === 'site' && !this.adNetwork.showSiteDomains) {
            grouping = 'site_id'
          }
          if (this.groupBy === 'spot') {
            grouping = 'spot_id'
          }
          return this.limitedItems.map((item) => item[grouping] || '')
        }
        return []
      },
      series() {
        return [this.firstSeries, this.secondSeries]
      },
      options() {
        return {
          ...this.defaultOptions,
          labels: this.labels,
          yaxis: this.yaxis,
          xaxis: this.xaxis,
          tooltip: this.tooltip
        }
      }
    },
    watch: {
      pagesCount() {
        this.chartPage = 1
      }
    },
    mounted() {
      this.width = this.$refs.el.offsetWidth
    },
    methods: {
      onResize() {
        this.width = this.$refs.el.clientWidth
      },
      changeAxis({ name, value }) {
        this.axis[name] = value
      }
    }
  }
</script>
